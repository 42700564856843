import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonHeaderModule } from '../common-header/common-header.module';
import { UIRouterModule } from '@uirouter/angular';
import { routes } from './app-common.routes';
import { AppComponent } from './components/app/app.component';
import { ViewportComponent } from './viewport/viewport.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { SelectCompanyComponent } from './components/select-company/select-company.component';
import { ComponentsModule } from '../components/components.module';

@NgModule({
  declarations: [
    AppComponent,
    ViewportComponent,
    SidebarComponent,
    SelectCompanyComponent
  ],
  imports: [
    CommonModule,
    ComponentsModule,
    BrowserAnimationsModule,
    CommonHeaderModule,
    UIRouterModule.forChild(routes),
    MatSidenavModule,
    MatIconModule,
    MatButtonModule,
    MatTooltipModule,
    MatMenuModule,
    MatSlideToggleModule,
    MatProgressSpinnerModule
  ],
  exports: [
    AppComponent
  ]
})
export class AppCommonModule { }
