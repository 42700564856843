<div id="select-company" class="tw-flex tw-flex-col tw-h-full" (click)="$event.stopPropagation()">
  <div class="company-menu-header">
    <div class="tw-p-6 tw-border-x-0 tw-border-t-0 tw-border-b tw-border-solid tw-border-gray-200">
      @if (companies.apiError) {
        <div id="errorBox" class="alert alert-danger tw-mb-4" role="alert">
          <strong>{{companies.errorMessage}}</strong> {{companies.apiError}}
        </div>
      }
      <div class="tw-flex tw-justify-between tw-items-center">
        <div class="tw-flex tw-items-center tw-gap-6">
          <initials-badge [name]="companyStateService.getUserCompanyName()" [size]="30"></initials-badge>
          <div>
            <div class="tw-text-[11px] tw-font-medium tw-text-gray-500">My Company</div>
            <div class="tw-font-medium">{{companyStateService.getUserCompanyName()}}</div>
          </div>
        </div>
        @if (companyStateService.isSubcompanySelected()) {
          <button
            id="reset-subcompany-button"
            type="button"
            mat-stroked-button
            (click)="resetCompany()"
            class="tw-font-medium"
          >Switch</button>
        }
      </div>
    </div>
    @if (companies.items.list.length > 0) {
      <div class="tw-px-6 tw-pb-6 tw-pt-2">
        <search-filter class="tw-w-full" [filterConfig]="filterConfig" [search]="search" [doSearch]="companies.doSearch"></search-filter>
        <div class="tw-flex tw-justify-between tw-mt-6">
          <label for="allCompaniesCheckbox" class="font-weight-medium u_nowrap mb-0 u_clickable" (click)="toggleAllCompanies(!allCompanies)">Show all sub-companies</label>
          <mat-slide-toggle id="allCompaniesCheckbox" class="ml-2" [checked]="allCompanies" (change)="toggleAllCompanies($event.checked)"></mat-slide-toggle>
        </div>
      </div>
    }
  </div>
  @if (companies.loadingItems || companies.items.list.length > 0) {
    <div
      class="tw-relative"
      [ngClass]="{'tw-h-32': companies.items.list.length === 0}"
    >
      @if (companies.items.list.length > 0) {
        <div
          class="tw-border-y tw-border-x-0 tw-border-solid tw-border-gray-200"
          scrollingList
          (scrollEndEvent)="companies.load()"
        >
          <table id="companySelectorListTable">
            <tbody>
              @for (company of companies.items.list; track company) {
                <tr class="company-list-item" (click)="setCompany(company)">
                  <td>
                    <initials-badge [name]="company.name" [size]="30"></initials-badge>
                  </td>
                  <td>
                    <span class="list-group-item-text tw-text-nowrap tw-whitespace-nowrap tw-font-medium">{{company.name}}</span>
                  </td>
                </tr>
              }
            </tbody>
          </table>
        </div>
      }
      @if (companies.loadingItems) {
        <div
          id="select-company-list-spinner"
          class="tw-absolute tw-inset-0 tw-flex tw-justify-center tw-items-center tw-h-full tw-bg-white/60"
          [ngClass]="{'tw-mt-4': companies.items.list.length === 0}"
        >
          <mat-spinner [diameter]="30"></mat-spinner>
        </div>
      }
    </div>
  }
  <div class="company-menu-footer">
    <a
      mat-button
      uiSref="apps.company.add"
      id="add-subcompany-menu-button"
      (click)="closeMenu.emit()"
    >
      <div class="tw-flex tw-gap-4">
        <mat-icon svgIcon="add" class="small medium"></mat-icon>
        <span>Add Sub-Company</span>
      </div>
    </a>
    <button mat-button type="button" (click)="licenseManagement()" id="company-licenses-button">
      <div class="tw-flex tw-gap-4 tw-items-center tw-justify-between tw-w-full">
        <div class="tw-flex tw-gap-4">
          <mat-icon svgIcon="hierarchy" class="small medium"></mat-icon>
          <span>Companies &amp; Licenses</span>
        </div>
        @if (!companyAccessService.accessCompanyLicenses) {
          <mat-icon class="small" svgIcon="requires-upgrade" id="company-licenses-requires-upgrade"></mat-icon>
        }
      </div>
    </button>
  </div>
</div>
